import React, { Fragment } from "react"
import { PropTypes } from "prop-types"
import styled from "@emotion/styled"
import { navigate } from "gatsby"
import { useMutation } from "react-apollo"
import { Heading } from "gatsby-interface"
import { billing as text } from "@modules/locales/default.json"
import { CheckoutForm, PaymentProvider } from "@modules/payment"
import { SUBSCRIBE_TO_PLAN } from "@modules/payment/queries"
import Review from "./Review"
import Footer from "./Footer"
import usePlanChangeState from "../shared/hooks/usePlanChangeState"
import { getPathToOrgSettings } from "@modules/organization/shared/utils"
import { useGenericError } from "@modules/modal"

const headerCss = theme => ({
  padding: `${theme.space[9]} 0 ${theme.space[7]}`,
})

const FormRoot = styled(`div`)`
  margin: 0 auto;
  max-width: 100%;
`

function CheckoutFormWithMutation(props) {
  const [mutate] = useMutation(SUBSCRIBE_TO_PLAN)
  return <CheckoutForm mutate={mutate} {...props} />
}

function Subscribe({ organizationId, plan, name }) {
  const {
    enablePlanUpdateNotification,
    clearPlanChangeRequest,
  } = usePlanChangeState(organizationId)
  const pathToOrgSettings = getPathToOrgSettings(organizationId)
  const throwError = useGenericError()

  return (
    <Fragment>
      <Heading css={headerCss} fontVariant="UI" id="add-payment-method">
        {text.addPayment}
      </Heading>
      <PaymentProvider>
        <FormRoot>
          <CheckoutFormWithMutation
            cancelCallback={() => navigate(pathToOrgSettings)}
            submitButtonText={text.startSubscription}
            cancelButtonText={text.cancel}
            planId={plan.id}
            organizationId={organizationId}
            showError={(message, creditCard) =>
              throwError({ message, creditCard, name })
            }
            showSuccess={() => {
              // Clear previous plan change info
              clearPlanChangeRequest()
              enablePlanUpdateNotification()
              navigate(pathToOrgSettings)
            }}
          >
            <Review plan={plan} />
          </CheckoutFormWithMutation>
        </FormRoot>
      </PaymentProvider>
      <Footer text={text.subscriptionNote} />
    </Fragment>
  )
}

Subscribe.propTypes = {
  organizationId: PropTypes.string,
  plan: PropTypes.object,
  name: PropTypes.string,
}

export default Subscribe
