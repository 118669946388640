import * as React from "react"
import { ToggleSwitch, Text, ThemeCss, BaseAnchor } from "gatsby-interface"
import { billing as text } from "@modules/locales/default.json"
import VisuallyHidden from "@modules/a11y/components/VisuallyHidden"
import {
  supportedPlans,
  SupportedPlanTier,
  supportedPlansOrder,
} from "@modules/billing/constants"
import { PlanInformationCard } from "@modules/billing/components/PlanInformationCard"
import { BillingInterval } from "@modules/graphql/types"
import { FormattedMessage } from "@modules/locales"
import { getPlanButtonCss } from "../stylesheets"
import { getColorsForPlanTier } from "../utils"
import { Link } from "gatsby"

const intervalSelectorCss: ThemeCss = theme => ({
  marginBottom: theme.space[7],
})

const plansListCss: ThemeCss = theme => ({
  display: `grid`,
  gridGap: theme.space[5],
  marginBottom: theme.space[9],
})

const customPlanCss: ThemeCss = theme => ({
  textAlign: `center`,
  color: theme.colors.grey[50],
  letterSpacing: "-0.7px",
  margin: 0,
})

export type PlanSelectorProps = {
  planOptions: readonly SupportedPlanTier[]
  selectedPlan?: SupportedPlanTier
  onSelectPlan: (tier: SupportedPlanTier) => void
  selectedInterval: BillingInterval
  onSelectInterval: (interval: BillingInterval) => void
  getPlanButtonLabel: (tier: SupportedPlanTier) => React.ReactNode
  getPlanButtonTo?: (tier: SupportedPlanTier) => string
  getPlanHint?: (tier: SupportedPlanTier) => React.ReactNode
  renderAction?: (tier: SupportedPlanTier) => React.ReactNode
}

export function PlanSelector({
  planOptions,
  selectedPlan,
  onSelectPlan,
  selectedInterval,
  onSelectInterval,
  getPlanButtonLabel,
  getPlanButtonTo,
  getPlanHint,
  renderAction,
}: PlanSelectorProps) {
  const optionsContainerCss: ThemeCss = theme => [
    plansListCss(theme),
    {
      [theme.mediaQueries.desktop]: {
        gridTemplateColumns: `repeat(${planOptions.length}, 1fr)`,
      },
    },
  ]

  return (
    <div>
      <div css={intervalSelectorCss}>
        <VisuallyHidden id="billingPlans__intervalTitle">
          {text.labels.billingInterval}
        </VisuallyHidden>
        <ToggleSwitch
          id="billingPlans__interval"
          valueOn={BillingInterval.Annual}
          labelOn={text.labels.billingIntervalOptionAnnual}
          valueOff={BillingInterval.Monthly}
          labelOff={text.labels.billingIntervalOptionMonthly}
          value={selectedInterval}
          onChange={e => onSelectInterval(e.target.value as BillingInterval)}
          aria-describedby="billingPlans__intervalTitle"
          tone="SUCCESS"
          css={theme => ({
            textTransform: `uppercase`,
            fontFamily: theme.fonts.heading,
          })}
        />
      </div>
      <div css={optionsContainerCss}>
        {planOptions.map(tier => {
          const planInformation = supportedPlans[tier]
          const previousTier =
            supportedPlansOrder[supportedPlansOrder.indexOf(tier) - 1]

          const prevTierPlanColorCss: ThemeCss = theme => ({
            color: previousTier
              ? getColorsForPlanTier(previousTier)(theme).primaryColor
              : undefined,
          })

          const amount =
            selectedInterval === BillingInterval.Annual
              ? planInformation.monthlyAmount[BillingInterval.Annual]
              : planInformation.monthlyAmount[BillingInterval.Monthly]

          const buttonCss = getPlanButtonCss(tier)

          let actionElement
          if (renderAction) {
            actionElement = renderAction(tier)
          } else {
            actionElement = getPlanButtonTo ? (
              <Link
                to={getPlanButtonTo(tier)}
                css={buttonCss}
                onClick={() => onSelectPlan(tier)}
              >
                {getPlanButtonLabel(tier)}
              </Link>
            ) : (
              <button css={buttonCss} onClick={() => onSelectPlan(tier)}>
                {getPlanButtonLabel(tier)}
              </button>
            )
          }

          return (
            <PlanInformationCard
              key={tier}
              tier={tier}
              title={planInformation.name}
              description={
                <FormattedMessage<never, "strong">
                  message={planInformation.description}
                  tags={{
                    strong: function(content) {
                      return <strong>{content}</strong>
                    },
                  }}
                />
              }
              amount={amount}
              featuresIntro={
                planInformation.featuresIntro && previousTier ? (
                  <FormattedMessage<"prevTierPlanName", "strong">
                    message={planInformation.featuresIntro}
                    values={{
                      prevTierPlanName: previousTier
                        ? supportedPlans[previousTier].name
                        : "",
                    }}
                    tags={{
                      strong: function(content) {
                        return (
                          <strong css={prevTierPlanColorCss}>{content}</strong>
                        )
                      },
                    }}
                  />
                ) : (
                  undefined
                )
              }
              features={planInformation.features}
              hint={getPlanHint ? getPlanHint(tier) : undefined}
              action={actionElement}
              selected={tier === selectedPlan}
            />
          )
        })}
      </div>
      <Text size="L" css={customPlanCss}>
        <FormattedMessage<never, "link">
          message={text.messages.contactSalesForCustomPlan}
          tags={{
            link: function(content) {
              return (
                <BaseAnchor href="mailto:sales@gatsbyjs.com">
                  {content}
                </BaseAnchor>
              )
            },
          }}
        />
      </Text>
    </div>
  )
}
