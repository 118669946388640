import React, { Fragment } from "react"
import PropTypes from "prop-types"
import { useMutation } from "react-apollo"
import styled from "@emotion/styled"
import * as Yup from "yup"
import { navigate } from "gatsby"
import { MdArrowForward, MdInfo } from "react-icons/md"
import {
  Heading,
  Button,
  InputConnectedField,
  TextAreaConnectedField,
  Text,
} from "gatsby-interface"
import { changePlanRequest as text } from "@modules/locales/default.json"
import { REQUEST_PLAN_CHANGE } from "../queries"
import { useTriggerErrorAlert } from "@modules/ui/components/ErrorAlert"
import Form from "@modules/form/components/Form"
import usePlanChangeState from "../shared/hooks/usePlanChangeState"
import { getPathToOrgSettings } from "@modules/organization/shared/utils"
import { useFlags } from "@modules/featureFlags"

const Row = styled(`div`)(props => ({
  display: `flex`,
  justifyContent: `space-between`,
  margin: `${props.theme.space[5]} 0`,
}))

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required(`The field is required`),
  lastName: Yup.string().required(`The field is required`),
  email: Yup.string()
    .email(`Invalid email`)
    .required(`Required`),
  reason: Yup.string().required(`The field is required`),
})

function RequestForm({
  user,
  changeTo,
  currentPlan,
  cancel,
  organizationId,
  buildsTier,
  hostingTier,
  nextBuildsTier,
  nextHostingTier,
}) {
  const { flags } = useFlags()
  const [mutate] = useMutation(REQUEST_PLAN_CHANGE)
  const [setError, errorAlert] = useTriggerErrorAlert()
  const { storePlanChangeRequest } = usePlanChangeState(organizationId)

  const heading = text[changeTo]
    ? text[changeTo].heading
    : `${text[`default`].heading} ${changeTo}`

  const lede = text[changeTo] ? text[changeTo].lede : text[`default`].lede

  const note = (text[changeTo] && text[changeTo].note) || null

  return (
    <div
      css={theme => ({
        maxWidth: `40rem`,
        margin: `${theme.space[9]} auto`,
      })}
    >
      <Fragment>
        <Heading>{heading}</Heading>
        <Text>{lede}</Text>
        {note && (
          <Text
            css={theme => ({
              background: theme.colors.yellow[5],
              border: `1px solid ${theme.colors.yellow[10]}`,
              color: theme.colors.yellow[90],
              margin: `${theme.space[8]} 0 ${theme.space[7]}`,
              padding: `${theme.space[5]} ${theme.space[7]}`,
            })}
          >
            <MdInfo
              css={{ verticalAlign: `middle`, transform: `translate(0, -10%)` }}
            />
            {` `}
            {note}
          </Text>
        )}
      </Fragment>

      <Form
        initialValues={{
          firstName: user ? user.firstName : ``,
          lastName: user ? user.lastName : ``,
          email: user ? user.email : ``,
          reason: ``,
        }}
        validationSchema={validationSchema}
        onSubmit={values => {
          return mutate({
            variables: {
              request: {
                firstName: values.firstName,
                lastName: values.lastName,
                email: values.email,
                plan: flags.multiTiersPlans
                  ? buildsTier
                  : changeTo.toUpperCase(),
                currentPlan: currentPlan,
                reason: values.reason,
                organizationId: organizationId,
                buildsTier: buildsTier,
                hostingTier: hostingTier,
                nextBuildsTier: nextBuildsTier,
                nextHostingTier: nextHostingTier,
              },
            },
          })
            .then(() => {
              storePlanChangeRequest(changeTo)
              navigate(getPathToOrgSettings(organizationId))
            })
            .catch(err => {
              setError(err)
            })
        }}
      >
        {({ isSubmitting }) => (
          <Form.FormElement
            applySpacing
            css={theme => ({
              margin: `${theme.space[9]} 0`,
              label: {
                fontSize: theme.fontSizes[2],
              },
            })}
          >
            <InputConnectedField label="First name" name="firstName" required />
            <InputConnectedField label="Last name" name="lastName" required />
            <InputConnectedField label="Email" name="email" required />
            <TextAreaConnectedField
              label="Reason for changing plan"
              name="reason"
              rows={4}
            />
            {errorAlert}
            <Row
              css={theme => ({
                marginTop: theme.space[5],
              })}
            >
              <Button variant="SECONDARY" tone="NEUTRAL" onClick={cancel}>
                {text.cancel}
              </Button>
              <Button
                type="submit"
                loading={isSubmitting}
                rightIcon={<MdArrowForward />}
                loadingLabel="Sending"
              >
                {text.submit}
              </Button>
            </Row>
          </Form.FormElement>
        )}
      </Form>
    </div>
  )
}

const tierPropTypes = PropTypes.oneOf([
  `FREE`,
  `STANDARD`,
  `PERFORMANCE`,
  `ENTERPRISE`,
])

RequestForm.propTypes = {
  user: PropTypes.object,
  changeTo: PropTypes.oneOf([
    `Free`,
    `Professional`,
    `Business`,
    `Enterprise`,
    `Standard Builds - Performance Hosting`,
    `Standard Builds - Free Hosting`,
    `Standard Builds - Standard Hosting`,
    `Performance Builds - Performance Hosting`,
    `Performance Builds - Free Hosting`,
    `Performance Builds - Standard Hosting`,
    `Free Builds - Performance Hosting`,
    `Free Builds - Free Hosting`,
    `Free Builds - Standard Hosting`,
  ]),
  cancel: PropTypes.func.isRequired,
  organizationId: PropTypes.string.isRequired,
  buildsTier: tierPropTypes,
  hostingTier: tierPropTypes,
  nextBuildsTier: tierPropTypes,
  nextHostingTier: tierPropTypes,
}

export default RequestForm
