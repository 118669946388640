import React from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import { MdAdd } from "react-icons/md"
import { Text, Heading, Button } from "gatsby-interface"
import { useFlags } from "@modules/featureFlags"

import {
  billing as text,
  paymentInformation as paymentText,
} from "@modules/locales/default.json"
import CreditCard from "./CreditCard"
import { UpdatePaymentForm } from "@modules/billing/components/UpdatePaymentForm"

const ReviewRoot = styled(`div`)(props => ({
  margin: 0,
  marginTop: props.theme.space[9],
  maxWidth: `100%`,
}))

const Header = styled(Text)(props => ({
  color: props.theme.colors.grey[50],
  fontSize: props.theme.fontSizes[1],
  textTransform: `uppercase`,
}))

const Content = styled(`section`)(props => ({
  alignItems: `center`,
  borderBottom: `1px solid ${props.theme.colors.grey[20]}`,
  bottom: 0,
  display: `flex`,
  flexWrap: `wrap`,
  justifyContent: `space-between`,
  padding: `${props.theme.space[8]} 0`,
}))

const TotalPrice = styled(Heading)(props => ({
  fontSize: props.theme.fontSizes[5],
  margin: 0,
}))

const Price = styled(Text)(props => ({
  color: props.theme.colors.grey[90],
  fontWeight: `bold`,
  margin: 0,
  padding: 0,
}))

const PaymentHeading = styled(Header)({
  borderTop: 0,
  margin: 0,
  paddingTop: 0,
})

const PaymentHeader = styled(`div`)(props => ({
  alignItems: `center`,
  display: `flex`,
  flexWrap: `wrap`,
  justifyContent: `space-between`,
  marginBottom: props.theme.space[5],
  width: `100%`,
}))

const PaymentDetails = styled(`div`)(props => ({
  alignItems: `center`,
  display: `flex`,
  flexWrap: `wrap`,
  justifyContent: `space-between`,
  width: `100%`,

  ".resubscribe": {
    width: `100%`,
  },

  [props.theme.mediaQueries.tablet]: {
    ".resubscribe": {
      width: `25%`,
    },
  },
}))

const PaymentText = styled(Text)({
  margin: 0,
  width: `100%`,
})

function Review({
  plan,
  billing,
  organizationId,
  name,
  children,
  setResponse,
  creditCard,
  paymentFormVisible,
  setPaymentFormVisible,
}) {
  const { flags } = useFlags()

  return (
    <ReviewRoot>
      <Header>{text.yourOrder}</Header>
      <Content>
        <Heading fontVariant="UI">
          {flags.multiTiersPlans ? (
            <React.Fragment>
              <span
                css={theme => ({
                  fontWeight: theme.fontWeights.body,
                })}
              >
                Plan:
              </span>{" "}
              {plan.name}
            </React.Fragment>
          ) : (
            `Gatsby ${plan.name}`
          )}
        </Heading>
        <Price>{`${plan.formattedAmount}`.replace(/\.00$/, ``)}</Price>
      </Content>
      <Content>
        <Heading fontVariant="UI">{text.total}</Heading>
        <TotalPrice fontVariant="UI">
          {`${plan.formattedAmount}`.replace(/\.00$/, ``)}
        </TotalPrice>
      </Content>
      <Content>
        <PaymentHeader>
          <PaymentHeading>
            {paymentFormVisible
              ? text.updatePaymentDetails
              : text.paymentDetails}
          </PaymentHeading>
          {billing && billing.creditCard && !paymentFormVisible && (
            <Button
              variant="SECONDARY"
              rightIcon={<MdAdd />}
              onClick={() => setPaymentFormVisible(true)}
            >
              {paymentText.updatePayment}
            </Button>
          )}
        </PaymentHeader>
        {paymentFormVisible && (
          <UpdatePaymentForm
            name={name}
            organizationId={organizationId}
            id={plan.id}
            setResponse={res => setResponse(res)}
            resourceType={`RESUBSCRIBE`}
            cancelCallback={() => setPaymentFormVisible(false)}
          />
        )}
        {!paymentFormVisible && (
          <PaymentDetails>
            <PaymentText
              className={billing && billing.creditCard ? `resubscribe` : ``}
            >
              {text.billed} {` `} {`${plan.interval}`.toLowerCase()}
              {` `} {text.billedDetails}
            </PaymentText>
            {billing && billing.creditCard && (
              <CreditCard creditCard={creditCard} />
            )}
          </PaymentDetails>
        )}
      </Content>
      {children}
    </ReviewRoot>
  )
}

Review.propTypes = {
  plan: PropTypes.object.isRequired,
  billing: PropTypes.object,
  organizationId: PropTypes.string,
  name: PropTypes.string,
}

export default Review
