import React from "react"

export default function(props) {
  return (
    <svg
      width="466"
      height="324"
      viewBox="0 0 466 324"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g opacity="0.05">
        <circle
          cx="276.001"
          cy="190"
          r="22.0019"
          transform="rotate(-180 276.001 190)"
          fill="#FFDF37"
        />
        <ellipse
          cx="219.998"
          cy="245.999"
          rx="22.0019"
          ry="22.0019"
          transform="rotate(-180 219.998 245.999)"
          fill="#BC027F"
        />
        <circle
          cx="444"
          cy="246.001"
          r="21.9996"
          transform="rotate(-180 444 246.001)"
          fill="#159BF3"
        />
        <ellipse
          cx="276.003"
          cy="302"
          rx="21.9996"
          ry="21.9996"
          transform="rotate(-180 276.003 302)"
          fill="#B17ACC"
        />
        <ellipse
          cx="220.002"
          cy="77.9981"
          rx="22.0019"
          ry="22.0019"
          transform="rotate(-180 220.002 77.9981)"
          fill="#59C156"
        />
        <circle
          cx="276.001"
          cy="134.001"
          r="22.0019"
          transform="rotate(-180 276.001 134.001)"
          fill="#FB8400"
        />
        <ellipse
          cx="220.002"
          cy="134.001"
          rx="22.0019"
          ry="22.0019"
          transform="rotate(-180 220.002 134.001)"
          fill="#663399"
        />
        <ellipse
          cx="388.001"
          cy="134.003"
          rx="21.9996"
          ry="21.9996"
          transform="rotate(-180 388.001 134.003)"
          fill="#05F7F4"
        />
        <circle
          cx="330"
          cy="189.998"
          r="22.0019"
          transform="rotate(-180 330 189.998)"
          fill="#FFD280"
        />
        <circle
          r="22.0019"
          transform="matrix(-1 0 0 1 -3.99901 134)"
          fill="#FFDF37"
        />
        <ellipse
          rx="21.9996"
          ry="21.9996"
          transform="matrix(-1 0 0 1 164 77.9986)"
          fill="#159BF3"
        />
        <circle
          r="21.9996"
          transform="matrix(-1 0 0 1 -3.99669 21.9996)"
          fill="#F2C4E3"
        />
        <ellipse
          rx="22.002"
          ry="22.0019"
          transform="matrix(-1 0 0 1 51.998 189.999)"
          fill="#A1DA9E"
        />
        <ellipse
          rx="21.9996"
          ry="21.9996"
          transform="matrix(-1 0 0 1 108.001 189.997)"
          fill="#159BF3"
        />
        <ellipse
          rx="22.002"
          ry="22.0019"
          transform="matrix(-1 0 0 1 51.998 134)"
          fill="#FB8400"
        />
      </g>
      <g opacity="0.5">
        <circle
          cx="110.667"
          cy="132.333"
          r="7.33398"
          transform="rotate(-180 110.667 132.333)"
          fill="#FFDF37"
        />
        <ellipse
          cx="91.9995"
          cy="151"
          rx="7.33398"
          ry="7.33398"
          transform="rotate(-180 91.9995 151)"
          fill="#BC027F"
        />
        <circle
          cx="166.667"
          cy="151.001"
          r="7.33321"
          transform="rotate(-180 166.667 151.001)"
          fill="#159BF3"
        />
        <ellipse
          cx="110.668"
          cy="169.667"
          rx="7.33321"
          ry="7.33321"
          transform="rotate(-180 110.668 169.667)"
          fill="#B17ACC"
        />
        <ellipse
          cx="92.0005"
          cy="94.9993"
          rx="7.33398"
          ry="7.33398"
          transform="rotate(-180 92.0005 94.9993)"
          fill="#59C156"
        />
        <ellipse
          cx="110.667"
          cy="113.667"
          rx="7.33398"
          ry="7.33397"
          transform="rotate(-180 110.667 113.667)"
          fill="#FB8400"
        />
        <ellipse
          cx="92.0005"
          cy="113.667"
          rx="7.33398"
          ry="7.33397"
          transform="rotate(-180 92.0005 113.667)"
          fill="#663399"
        />
        <ellipse
          cx="148"
          cy="113.668"
          rx="7.33321"
          ry="7.3332"
          transform="rotate(-180 148 113.668)"
          fill="#05F7F4"
        />
        <circle
          cx="128.667"
          cy="132.333"
          r="7.33398"
          transform="rotate(-180 128.667 132.333)"
          fill="#FFD280"
        />
        <circle
          r="7.33398"
          transform="matrix(-1 0 0 1 17.3335 113.667)"
          fill="#FFDF37"
        />
        <ellipse
          rx="7.33397"
          ry="7.33397"
          transform="matrix(-1 0 0 1 -1.3325 95.0002)"
          fill="#00BDB6"
        />
        <ellipse
          rx="7.33321"
          ry="7.33321"
          transform="matrix(-1 0 0 1 73.3333 94.9995)"
          fill="#159BF3"
        />
        <ellipse
          rx="7.33321"
          ry="7.33321"
          transform="matrix(-1 0 0 1 17.3343 76.3332)"
          fill="#F2C4E3"
        />
        <ellipse
          rx="7.33397"
          ry="7.33398"
          transform="matrix(-1 0 0 1 -1.3325 150.999)"
          fill="#FF5A54"
        />
        <circle
          r="7.33398"
          transform="matrix(-1 0 0 1 35.9995 132.333)"
          fill="#A1DA9E"
        />
        <ellipse
          rx="7.33397"
          ry="7.33398"
          transform="matrix(-1 0 0 1 -1.3325 132.333)"
          fill="#CCFFFC"
        />
        <ellipse
          rx="7.3332"
          ry="7.33321"
          transform="matrix(-1 0 0 1 54.6673 132.332)"
          fill="#159BF3"
        />
        <circle
          r="7.33398"
          transform="matrix(-1 0 0 1 35.9995 113.667)"
          fill="#FB8400"
        />
      </g>
    </svg>
  )
}
