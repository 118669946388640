import React from "react"

export default function() {
  return (
    <svg
      width="36"
      height="27"
      viewBox="0 0 36 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="36"
        height="26"
        rx="2"
        transform="matrix(1 0 0 -1 0 26.3175)"
        fill="#F0F0F2"
      />
      <line x1="12" y1="10" y2="10" stroke="#F5F5F5" strokeWidth="2" />
      <line x1="12" y1="17" y2="17" stroke="#F5F5F5" strokeWidth="2" />
      <path d="M36 17H25" stroke="#F5F5F5" strokeWidth="2" />
      <path
        d="M19 0.48877V3C19 4.10457 18.1046 5 17 5H14C12.8954 5 12 5.89543 12 7V20C12 21.1046 12.8954 22 14 22H23C24.1046 22 25 21.1046 25 20V11C25 9.89543 25.8954 9 27 9H36"
        stroke="#F5F5F5"
        strokeWidth="2"
      />
      <line
        x1="18"
        y1="21"
        x2="18"
        y2="26.98"
        stroke="#F5F5F5"
        strokeWidth="2"
      />
    </svg>
  )
}
