import React from "react"

export default function(props) {
  return (
    <svg
      width="257"
      height="520"
      viewBox="0 0 257 520"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g opacity="0.25">
        <path
          opacity="0.5"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M137.858 0C141.961 0 145.286 3.32503 145.286 7.42857C145.286 11.5321 141.961 14.8571 137.858 14.8571C133.754 14.8571 130.429 11.5321 130.429 7.42857C130.429 3.32503 133.754 0 137.858 0Z"
          fill="#FFDF37"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-3.28557 68.7143C5.9474 68.7143 13.4287 76.1956 13.4287 85.4286C13.4287 94.6615 5.9474 102.143 -3.28557 102.143C-12.5185 102.143 -19.9999 94.6615 -19.9999 85.4286C-19.9999 76.1956 -12.5185 68.7143 -3.28557 68.7143Z"
          fill="#BC027F"
        />
        <path
          opacity="0.5"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M168.5 277.643C178.759 277.643 187.071 285.955 187.071 296.214C187.071 306.473 178.759 314.786 168.5 314.786C158.241 314.786 149.928 306.473 149.928 296.214C149.928 285.955 158.241 277.643 168.5 277.643Z"
          fill="#3FA9F5"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M247.428 355.643C252.558 355.643 256.714 359.799 256.714 364.929C256.714 370.058 252.558 374.214 247.428 374.214C242.299 374.214 238.142 370.058 238.142 364.929C238.142 359.799 242.299 355.643 247.428 355.643Z"
          fill="#FFB238"
        />
        <path
          opacity="0.5"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M66.357 416.929C75.59 416.929 83.0713 424.41 83.0713 433.643C83.0713 442.876 75.59 450.357 66.357 450.357C57.124 450.357 49.6427 442.876 49.6427 433.643C49.6427 424.41 57.124 416.929 66.357 416.929Z"
          fill="#37B635"
        />
        <path
          opacity="0.5"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M205.643 486.571C214.876 486.571 222.357 494.053 222.357 503.286C222.357 512.519 214.876 520 205.643 520C196.41 520 188.929 512.519 188.929 503.286C188.929 494.053 196.41 486.571 205.643 486.571Z"
          fill="#73FFF7"
        />
      </g>
    </svg>
  )
}
